import styled from "styled-components";

import { GLOBAL, GREY } from "@patchworkhealth/web-components";

export const LeaveRequestsRow = styled.div.attrs({ role: "row" })`
  border-bottom: 1px solid ${GLOBAL.borderGreyLight};
  display: grid;
  gap: 9px;
  padding: 20px 24px;
  align-items: center;
`;

export const GridContainer = styled.div.attrs({ role: "table" })`
  flex: 1 0 0;
  min-height: 300px;
  overflow: auto;
  border: 1px solid ${GREY.two};
  background: ${GLOBAL.white};
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0;

  ${LeaveRequestsRow} {
    grid-template-columns: 3fr 1fr 1fr 1fr 0.1fr 0.5fr;
  }
`;

export const GridHeader = styled(LeaveRequestsRow)`
  background: ${GLOBAL.white};
  position: sticky;
  top: 0;
  align-items: center;

  && {
    padding: 10px 10px;
  }
`;

export const GridHeaderTitle = styled.div`
  display: flex;
  color: ${GREY.four};
  font-size: 9px;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;

  &:first-child {
    padding-left: 15px;
  }
`;
