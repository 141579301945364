import * as Types from '../../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type OrganisationCardFragment = { __typename?: 'Organisation', description?: string | null, id: number, logoUrl?: string | null, name?: string | null, agencyEmail?: string | null, tsoPhone?: string | null, leadEmployer: boolean, allowsExternalApplicants: boolean, collaborativeBank?: { __typename?: 'CollaborativeBank', contractText?: string | null, usesLeadEmployerProcess: boolean, id: number, name: string, organisations: Array<{ __typename?: 'Organisation', id: number, name?: string | null }> } | null, organisationStaffGroups: Array<{ __typename?: 'OrganisationStaffGroup', askQuestion: boolean, question?: string | null, staffBankAgreementForm?: string | null, tsoEmail?: string | null, tsoPhone?: string | null, staffGroup?: { __typename?: 'StaffGroup', id: Types.StaffGroupEnum, idValue: number, title: string } | null }>, organisationType?: { __typename?: 'OrganisationType', id: number, name?: string | null } | null };

export type OrganisationCardsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type OrganisationCardsQuery = { __typename?: 'Query', organisations: Array<{ __typename?: 'Organisation', description?: string | null, id: number, logoUrl?: string | null, name?: string | null, agencyEmail?: string | null, tsoPhone?: string | null, leadEmployer: boolean, allowsExternalApplicants: boolean, collaborativeBank?: { __typename?: 'CollaborativeBank', contractText?: string | null, usesLeadEmployerProcess: boolean, id: number, name: string, organisations: Array<{ __typename?: 'Organisation', id: number, name?: string | null }> } | null, organisationStaffGroups: Array<{ __typename?: 'OrganisationStaffGroup', askQuestion: boolean, question?: string | null, staffBankAgreementForm?: string | null, tsoEmail?: string | null, tsoPhone?: string | null, staffGroup?: { __typename?: 'StaffGroup', id: Types.StaffGroupEnum, idValue: number, title: string } | null }>, organisationType?: { __typename?: 'OrganisationType', id: number, name?: string | null } | null }>, currentWorker?: { __typename?: 'Worker', staffGroup?: { __typename?: 'StaffGroup', idValue: number } | null, organisationRegistrations: Array<{ __typename?: 'OrganisationRegistration', id: number, approved: boolean, requiresActivation: boolean, approvedAt?: any | null, esrNumber?: string | null, grade?: { __typename?: 'Grade', title: string } | null, organisation: { __typename?: 'Organisation', id: number } }> } | null };

export const OrganisationCardFragmentDoc = gql`
    fragment OrganisationCard on Organisation {
  description
  id
  logoUrl
  name
  agencyEmail
  tsoPhone
  leadEmployer
  allowsExternalApplicants
  collaborativeBank {
    contractText
    usesLeadEmployerProcess
    id
    name
    organisations {
      id
      name
    }
  }
  organisationStaffGroups {
    askQuestion
    question
    staffBankAgreementForm
    tsoEmail
    tsoPhone
    staffGroup {
      id
      idValue
      title
    }
  }
  organisationType {
    id
    name
  }
}
    `;
export const OrganisationCardsDocument = gql`
    query OrganisationCards($ids: [Int!]) {
  organisations(ids: $ids) {
    ...OrganisationCard
  }
  currentWorker {
    staffGroup {
      idValue
    }
    organisationRegistrations {
      id
      approved
      requiresActivation
      approvedAt
      esrNumber
      grade {
        title
      }
      organisation {
        id
      }
    }
  }
}
    ${OrganisationCardFragmentDoc}`;

/**
 * __useOrganisationCardsQuery__
 *
 * To run a query within a React component, call `useOrganisationCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationCardsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useOrganisationCardsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationCardsQuery, OrganisationCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationCardsQuery, OrganisationCardsQueryVariables>(OrganisationCardsDocument, options);
      }
export function useOrganisationCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationCardsQuery, OrganisationCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationCardsQuery, OrganisationCardsQueryVariables>(OrganisationCardsDocument, options);
        }
export type OrganisationCardsQueryHookResult = ReturnType<typeof useOrganisationCardsQuery>;
export type OrganisationCardsLazyQueryHookResult = ReturnType<typeof useOrganisationCardsLazyQuery>;
export type OrganisationCardsQueryResult = Apollo.QueryResult<OrganisationCardsQuery, OrganisationCardsQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockOrganisationCardsQuery((req, res, ctx) => {
 *   const { ids } = req.variables;
 *   return res(
 *     ctx.data({ organisations, currentWorker })
 *   )
 * })
 */
export const mockOrganisationCardsQuery = (resolver: ResponseResolver<GraphQLRequest<OrganisationCardsQueryVariables>, GraphQLContext<OrganisationCardsQuery>, any>) =>
  graphql.query<OrganisationCardsQuery, OrganisationCardsQueryVariables>(
    'OrganisationCards',
    resolver
  )
