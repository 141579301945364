import { useState } from "react";
import { FormLabel } from "react-bootstrap";
import styled from "styled-components";

import {
  Button,
  CloseButton,
  GREY,
  Loading,
  ModalHeader,
  ModalTitle,
  RBBLUE,
  RBCYAN,
  RBGREY,
  RBPURPLE,
} from "@patchworkhealth/web-components";

import { RosteringLeaveRequestQuery } from "components/LeaveManagement/__generated__/LeaveManagement.generated";
import { checkLeaveType } from "components/LeaveManagement/LeaveManagement.helpers";
import { ModalUnlockBox } from "components/LeaveManagement/LeaveRecordModal/components/ModalUnlockBox";
import { LeaveStatus } from "components/LeaveManagement/LeaveRecordTable/LeaveRecordTable";
import { MissedShifts } from "components/LeaveManagement/MissedShifts";
import { Divider, Heading } from "components/Style";
import { leaveEntitlementDurationString } from "helpers/leaveEntitlementDurationString";
import { formatTimeInTZ } from "helpers/momentHelpers";
import { LeaveInfoIcon } from "icons/leaveManagementIcons";

import { RosteringLeaveRequestStatusEnum } from "../../../../baseCODEGEN";

interface Props {
  onHide: () => void;
  leaveRecord: RosteringLeaveRequestQuery | undefined;
  leaveRecordLoading: boolean;
  setShowCancelModal: (show: boolean) => void;
}

export const LeaveRecordModalContent = ({
  onHide,
  leaveRecord,
  leaveRecordLoading,
  setShowCancelModal,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const leaveType = leaveRecord?.rosteringLeaveRequest?.measurementType;

  const total = leaveRecord?.rosteringLeaveRequest?.total;

  const leaveTaken =
    leaveRecord?.rosteringLeaveRequest?.rosteringLeaveType?.leaveType;

  const filteredApprovals =
    leaveRecord?.rosteringLeaveRequest?.rosteringLeaveRequestApprovals?.filter(
      (approval) => approval.status === "approved"
    );

  const pendingLeaveStatus =
    leaveRecord?.rosteringLeaveRequest?.status === "pending";
  const sickLeave = leaveTaken === "sick";
  const approvedLeaveStatus =
    leaveRecord?.rosteringLeaveRequest?.status === "approved";

  const cancellableRequest =
    leaveRecord?.rosteringLeaveRequest?.cancellableRequest;

  return (
    <>
      <ModalHeader>
        <ModalTitle>View {leaveTaken} Leave</ModalTitle>

        <SubmittedStatus>
          <p>
            {leaveRecord?.rosteringLeaveRequest?.status ===
              RosteringLeaveRequestStatusEnum.Cancelled && "Originally "}
            Submitted:{" "}
            <strong>
              {formatTimeInTZ(
                leaveRecord?.rosteringLeaveRequest?.createdAt,
                "Do MMM YYYY"
              )}
            </strong>
          </p>
          <LeaveStatus status={leaveRecord?.rosteringLeaveRequest?.status}>
            {leaveRecord?.rosteringLeaveRequest?.status}
          </LeaveStatus>
          <CloseButton
            onClick={() => {
              setIsOpen(false);
              onHide();
            }}
          />
        </SubmittedStatus>
      </ModalHeader>

      {/* Modal Body Content ****************************************** */}
      {leaveRecord?.rosteringLeaveRequest?.locked && <ModalUnlockBox />}

      {leaveRecordLoading ? (
        <ModalBody>
          <Loading style={{ minHeight: "200px" }} />
        </ModalBody>
      ) : (
        <ModalBody>
          {leaveRecord?.rosteringLeaveRequest?.status ===
            RosteringLeaveRequestStatusEnum.Cancelled && (
            <CancelledState style={{ width: "fit-content" }}>
              You have cancelled this leave request.
            </CancelledState>
          )}
          {leaveRecord?.rosteringLeaveRequest?.status ===
            RosteringLeaveRequestStatusEnum.PendingCancellation &&
            leaveRecord?.rosteringLeaveRequest?.reasonForCancellation && (
              <CancelledState>
                {`You have requested to cancel this leave, for the reason “${leaveRecord?.rosteringLeaveRequest?.reasonForCancellation}”.`}
              </CancelledState>
            )}
          <ModalLeft status={leaveRecord?.rosteringLeaveRequest?.status}>
            <TextContainer status={leaveRecord?.rosteringLeaveRequest?.status}>
              <label>{checkLeaveType(leaveType)} TAKEN</label>
              <Heading size="3" mb="15">
                {leaveEntitlementDurationString(
                  leaveRecord?.rosteringLeaveRequest?.total,
                  leaveType,
                  leaveRecord?.rosteringLeaveRequest?.hoursInDay
                )}
              </Heading>

              <div className="info__flex">
                <div style={{ minWidth: "fit-content" }}>
                  <label>BEGINS</label>

                  {leaveRecord?.rosteringLeaveRequest?.startDate ? (
                    <div className="time__flex">
                      <span>
                        {formatTimeInTZ(
                          leaveRecord?.rosteringLeaveRequest?.startDate,
                          "Do MMM YYYY"
                        )}
                      </span>
                      <br />
                      <span>
                        {formatTimeInTZ(
                          leaveRecord?.rosteringLeaveRequest?.startDate,
                          "HH:mm a"
                        )}
                      </span>
                    </div>
                  ) : (
                    <span> - </span>
                  )}
                </div>

                <div style={{ marginLeft: "50px", minWidth: "fit-content" }}>
                  <label>ENDS</label>

                  {leaveRecord?.rosteringLeaveRequest?.endDate ? (
                    <div className="time__flex">
                      <span>
                        {formatTimeInTZ(
                          leaveRecord?.rosteringLeaveRequest?.endDate,
                          "Do MMM YYYY"
                        )}
                      </span>
                      <br />
                      <span>
                        {formatTimeInTZ(
                          leaveRecord?.rosteringLeaveRequest?.endDate,
                          "HH:mm a"
                        )}
                      </span>
                    </div>
                  ) : (
                    <span> - </span>
                  )}
                </div>

                <div style={{ marginLeft: "50px" }}>
                  {leaveRecord?.rosteringLeaveRequest?.rosteringLeaveType
                    ?.leaveType === "study" && (
                    <>
                      <label>LEAVE REASON</label>
                      <h4
                        style={{
                          fontSize: "14px",
                          color: `${GREY.ten}`,
                          marginBottom: "20px",
                        }}
                      >
                        {
                          leaveRecord?.rosteringLeaveRequest
                            ?.rosteringLeaveReason?.name
                        }
                      </h4>
                    </>
                  )}
                </div>

                <div style={{ marginLeft: "50px" }}>
                  <label>NOTES</label>
                  <h4
                    style={{
                      fontSize: "14px",
                      color: `${GREY.ten}`,
                      marginBottom: "20px",
                    }}
                  >
                    {leaveRecord?.rosteringLeaveRequest?.reasonForLeave}
                  </h4>
                </div>
              </div>
            </TextContainer>

            <br />
          </ModalLeft>
          {leaveRecord?.rosteringLeaveRequest?.status ===
            RosteringLeaveRequestStatusEnum.Cancelled && (
            <ReturnedEntitlementTooltip>
              <LeaveInfoIcon />{" "}
              <p>
                {leaveRecord?.rosteringLeaveRequest?.total}{" "}
                {checkLeaveType(leaveType, total)}{" "}
                {total === 1 ? "has" : "have"} been added back to your
                entitlement
              </p>
            </ReturnedEntitlementTooltip>
          )}

          {(leaveRecord?.rosteringLeaveRequest?.status === "pending" ||
            leaveRecord?.rosteringLeaveRequest?.status === "approved" ||
            sickLeave) && (
            <>
              {!!leaveRecord?.rosteringLeaveRequest?.missedShifts?.length && (
                <ModalRight>
                  <FormLabel style={{ fontSize: "14px", fontWeight: "600" }}>
                    Missed Shifts (
                    {leaveRecord?.rosteringLeaveRequest?.missedShifts?.length})
                  </FormLabel>
                  <MissedShifts
                    missedShifts={
                      leaveRecord?.rosteringLeaveRequest?.missedShifts
                    }
                  />
                </ModalRight>
              )}
            </>
          )}

          {(leaveRecord?.rosteringLeaveRequest?.status === "pending" ||
            leaveRecord?.rosteringLeaveRequest?.status === "approved" ||
            sickLeave) && (
            <>
              {!leaveRecord?.rosteringLeaveRequest?.missedShifts?.length && (
                <ModalRight>
                  <FormLabel style={{ fontSize: "14px", fontWeight: "600" }}>
                    Missed Shifts
                  </FormLabel>
                  <p style={{ marginTop: 10 }}>No shifts missed</p>
                </ModalRight>
              )}
            </>
          )}
        </ModalBody>
      )}

      <div style={{ margin: "0px 35px 0px 32px" }}>
        <Divider line="solid" top="0" bottom="22" />
      </div>

      {!!leaveRecord?.rosteringLeaveRequest?.rosteringLeaveRequestApprovals
        ?.length &&
        leaveRecord?.rosteringLeaveRequest?.status !== "cancelled" &&
        !sickLeave && (
          <>
            <Approvals open={isOpen}>
              <ApprovalsHeader open={isOpen}>
                <LeaveStatus
                  status={leaveRecord?.rosteringLeaveRequest?.status}
                >
                  {leaveRecord?.rosteringLeaveRequest?.status}
                </LeaveStatus>
                <p>
                  {filteredApprovals?.length}/
                  {
                    leaveRecord?.rosteringLeaveRequest
                      ?.rosteringLeaveRequestApprovals?.length
                  }{" "}
                  {leaveRecord?.rosteringLeaveRequest
                    ?.rosteringLeaveRequestApprovals?.length === 1
                    ? "has "
                    : "have "}
                  accepted your{" "}
                  {leaveRecord?.rosteringLeaveRequest?.status ===
                  RosteringLeaveRequestStatusEnum.PendingCancellation
                    ? "cancellation request"
                    : "leave"}
                </p>
                <div
                  onClick={() => setIsOpen((prev) => !prev)}
                  className="h-17.5px w-20px cursor-pointer flex justify-center items-center"
                >
                  <ChevronIcon />
                </div>
              </ApprovalsHeader>

              <ApprovalsBody open={isOpen}>
                {leaveRecord?.rosteringLeaveRequest?.rosteringLeaveRequestApprovals?.map(
                  ({ approver, status, reasonForDecision, id }) => (
                    <Approver key={id}>
                      <div
                        style={{
                          gap: "4px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <LeaveStatus status={status}>{status}</LeaveStatus>
                        <ApproverName>{approver?.fullName}</ApproverName>
                        <ApproverEmail>{approver?.email}</ApproverEmail>
                      </div>
                      <ApproverDivide />
                      <div style={{ padding: "8px" }}>
                        <ApproverDepartment>Notes</ApproverDepartment>
                        <ApproverDecision>
                          {reasonForDecision || "No reason provided."}
                        </ApproverDecision>
                      </div>
                    </Approver>
                  )
                )}
              </ApprovalsBody>
            </Approvals>

            {leaveRecord?.rosteringLeaveRequest?.status === "rejected" && (
              <ContactCoordinatorInfo>
                <LeaveInfoIcon />
                Please contact the relevant rota coordinator before
                re-submitting your leave request.
              </ContactCoordinatorInfo>
            )}

            <div style={{ margin: "0px 35px 0px 32px" }}>
              <Divider line="solid" top="12" bottom="32" />
            </div>
          </>
        )}

      <ModalFooter>
        <Button
          variant="secondary"
          onClick={() => {
            setIsOpen(false);
            onHide();
          }}
        >
          Close
        </Button>

        {cancellableRequest && (pendingLeaveStatus || approvedLeaveStatus) && (
          <Button
            variant="danger"
            type="submit"
            disabled={leaveRecord?.rosteringLeaveRequest?.locked}
            onClick={() => {
              setShowCancelModal(true);
            }}
          >
            Cancel Leave Request
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

const ContactCoordinatorInfo = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin: 10px 0 10px 30px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

const Approvals = styled.div<{ open: boolean }>`
  margin: 0 32px;
  width: 660px;
  height: ${({ open }) => (open ? "auto" : "32px")};
  transition: all 0.2s ease-in-out;
`;

const Approver = styled.div`
  background: ${GREY.one};
  border-radius: 6px;
  height: 105px;
  margin: 12px 0;
  padding: 11px 20px;
  width: 635px;
  display: flex;
`;

const ApproverName = styled.div`
  font-size: 13px;
  font-weight: 600;
  left: 20px;
  top: 38px;
  width: 232px;
`;

const ApproverEmail = styled.div`
  color: ${GREY.five};
  font-size: 12px;
  left: 20px;
  top: 70px;
  width: 190px;
`;

const ApproverDivide = styled.div`
  border-left: 1px solid ${GREY.three};
  height: 75px;
  left: 255px;
  opacity: 0.7;
  top: 10px;
  width: 1.5px;
`;

const ApproverDepartment = styled.div`
  font-size: 12px;
  font-weight: 600;
  left: 270px;
`;

const ApproverDecision = styled.div`
  color: ${GREY.five};
  font-size: 12px;
  left: 270px;
  top: 27px;
`;

const ApprovalsHeader = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;

  p {
    padding-left: 20px;
    padding-right: 10px;
    color: ${GREY.nine};
    font-weight: 400;
    margin-bottom: inherit;
  }

  svg {
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
    transition: all 0.2s ease-in-out;
  }
`;

const ApprovalsBody = styled.div<{ open: boolean }>`
  margin-top: 12px;
  max-height: 500px;
  overflow: auto;
  opacity: ${({ open }) => (open ? "1" : "0")};
  position: relative;
  z-index: ${({ open }) => (open ? 1 : -1)};
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 32px;
  max-width: 940px;

  label {
    color: rgb(125, 135, 147);
    margin: 0;
  }

  .info__flex {
    display: flex;
  }

  @media (max-width: 992px) {
    .modal-content,
    .modal-dialog {
      width: 100% !important;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const SubmittedStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 25px 38px 0;

  button {
    margin-right: 8px;
  }

  @media (max-width: 468px) {
    flex-direction: column;
    text-align: center;
    button {
      font-size: 12px;
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;

const ModalLeft = styled.div<{ status: string | null | undefined }>`
  flex: 1;
  opacity: ${(props) => (props.status === "cancelled" ? 0.5 : 1)};
  background: ${(props) => {
    if (props.status === RosteringLeaveRequestStatusEnum.Cancelled) return null;
    if (props.status === RosteringLeaveRequestStatusEnum.PendingCancellation)
      return RBGREY.one;
    return RBCYAN.two;
  }};
  height: 168px;
  width: 660px;
  border-radius: 8px;
  margin-bottom: ${(props) => (props.status === "cancelled" ? 0 : "20px")};
`;

const TextContainer = styled.div<{ status: string | null | undefined }>`
  /* padding: 25px 70px 0 0; */
  padding: ${(props) =>
    props.status === "cancelled" ? "25px 70px 0 0" : "25px 70px 25px 30px"};
`;

const ModalRight = styled.div`
  flex: 1;
  width: 660px;
`;

const CancelledState = styled.div`
  background: ${RBPURPLE.one};
  border-left: 17px solid ${RBPURPLE.five};
  border-radius: 8px;
  color: ${GREY.ten};
  font-size: 13px;
  font-weight: 600;
  height: fit-content;
  padding: 13px 15px;
  width: 660px;
  margin-bottom: 15px;
`;

const ReturnedEntitlementTooltip = styled.div`
  background: ${RBCYAN.two};
  border-radius: 6px;
  width: 367px;
  height: 36px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 16px;

  p {
    color: ${RBBLUE.six} !important;
    font-size: 12px !important;
    margin-left: 5px;
  }
`;

const ChevronIcon = () => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0303 0.21967C11.3232 0.512563 11.3232 0.987437 11.0303 1.28033L6.53033 5.78033C6.23744 6.07322 5.76256 6.07322 5.46967 5.78033L0.96967 1.28033C0.676777 0.987436 0.676777 0.512563 0.96967 0.219669C1.26256 -0.0732238 1.73744 -0.0732237 2.03033 0.21967L6 4.18934L9.96967 0.21967C10.2626 -0.0732234 10.7374 -0.0732233 11.0303 0.21967Z"
      fill="#7D8793"
    />
  </svg>
);
