import * as Types from '../../baseCODEGEN';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
const defaultOptions = {} as const;
export type UpdateProfileMutationVariables = Types.Exact<{
  staffGroupId?: Types.InputMaybe<Types.Scalars['Int']>;
  postcode?: Types.InputMaybe<Types.Scalars['String']>;
  regBodyNumber?: Types.InputMaybe<Types.Scalars['String']>;
  firstName?: Types.InputMaybe<Types.Scalars['String']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']>;
  dateOfBirth?: Types.InputMaybe<Types.Scalars['String']>;
  phoneNumber?: Types.InputMaybe<Types.Scalars['String']>;
  addressLine1?: Types.InputMaybe<Types.Scalars['String']>;
  addressLine2?: Types.InputMaybe<Types.Scalars['String']>;
  city?: Types.InputMaybe<Types.Scalars['String']>;
  preferredName?: Types.InputMaybe<Types.Scalars['String']>;
  dbsNumber?: Types.InputMaybe<Types.Scalars['String']>;
  niNumber?: Types.InputMaybe<Types.Scalars['String']>;
  filterGradeId?: Types.InputMaybe<Types.Scalars['Int']>;
  ediGenderId?: Types.InputMaybe<Types.Scalars['Int']>;
  ediConsent?: Types.InputMaybe<Types.Scalars['Boolean']>;
  ediCountryOfQualificationId?: Types.InputMaybe<Types.Scalars['Int']>;
  ediDisabilityId?: Types.InputMaybe<Types.Scalars['Int']>;
  ediEthnicityId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', workerOnboardingUpdateProfile?: { __typename?: 'UpdateWorkerProfilePayload', worker?: { __typename?: 'Worker', firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, gender?: Types.GenderEnum | null, dateOfBirth?: string | null, email?: string | null, postcode?: string | null, regBodyNumber?: string | null, ediGender?: { __typename?: 'Gender', id: number, value: string } | null, staffGroup?: { __typename?: 'StaffGroup', id: Types.StaffGroupEnum, specialities?: Array<{ __typename?: 'Speciality', id: number, title?: string | null }> | null } | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type DeleteProfilePicMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DeleteProfilePicMutation = { __typename?: 'Mutation', deleteProfilePicture?: { __typename?: 'DeleteProfilePicturePayload', errors: Array<{ __typename?: 'ValidationError', message: string }>, worker?: { __typename?: 'Worker', email?: string | null, profilePictureUrl?: string | null } | null } | null };

export type UpdateEmploymentMutationVariables = Types.Exact<{
  dbsNumber?: Types.InputMaybe<Types.Scalars['String']>;
  niNumber?: Types.InputMaybe<Types.Scalars['String']>;
  filterGradeId?: Types.InputMaybe<Types.Scalars['Int']>;
  specialityIds?: Types.InputMaybe<Array<Types.Scalars['Int']> | Types.Scalars['Int']>;
}>;


export type UpdateEmploymentMutation = { __typename?: 'Mutation', updateEmploymentInfo?: { __typename?: 'UpdateEmploymentInfoPayload', worker?: { __typename?: 'Worker', niNumber?: string | null, dbsNumber?: string | null, id: string, filterGrade?: { __typename?: 'FilterGrade', id: number } | null, specialities: Array<{ __typename?: 'Speciality', id: number }> } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type UpdateAvatarMutationVariables = Types.Exact<{
  file: Types.Scalars['String'];
  fileName: Types.Scalars['String'];
}>;


export type UpdateAvatarMutation = { __typename?: 'Mutation', uploadProfilePicture?: { __typename?: 'UploadProfilePicturePayload', worker?: { __typename?: 'Worker', firstName?: string | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type SendResetPasswordInstructionsMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;


export type SendResetPasswordInstructionsMutation = { __typename?: 'Mutation', sendResetPasswordInstructions?: boolean | null };

export type RefreshTokenMutationMutationVariables = Types.Exact<{
  refreshToken: Types.Scalars['String'];
}>;


export type RefreshTokenMutationMutation = { __typename?: 'Mutation', refreshToken?: { __typename?: 'NonAuthorisedUser', refreshToken?: string | null, token?: string | null } | null };

export type DeleteDocMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type DeleteDocMutation = { __typename?: 'Mutation', deleteWorkerUpload?: { __typename?: 'DeleteWorkerUploadPayload', documentType?: { __typename?: 'DocumentType', id: number, title?: string | null, mid?: string | null, allowedFormats?: string | null, documentNumberVisibility?: number | null, documentTitleVisibility?: number | null, issuerVisibility?: number | null, issueDateVisibility?: number | null, expirationDateVisibility?: number | null, qualificationNameVisibility?: number | null, multipleUploadsAllowed?: boolean | null, workerUploads?: Array<{ __typename?: 'WorkerUpload', id: number, issuer?: string | null, documentNumber?: string | null, documentTitle?: string | null, qualificationName?: string | null, issueDate?: string | null, expirationDate?: string | null, imageUrl?: string | null, fileName?: string | null, trashedAt?: string | null }> | null, archivedWorkerUploads?: Array<{ __typename?: 'WorkerUpload', id: number, issuer?: string | null, documentNumber?: string | null, documentTitle?: string | null, qualificationName?: string | null, issueDate?: string | null, expirationDate?: string | null, imageUrl?: string | null, fileName?: string | null, trashedAt?: string | null }> | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type AddDocMutationVariables = Types.Exact<{
  file: Types.Scalars['String'];
  fileName: Types.Scalars['String'];
  documentTypeId: Types.Scalars['Int'];
  documentNumber?: Types.InputMaybe<Types.Scalars['String']>;
  documentTitle?: Types.InputMaybe<Types.Scalars['String']>;
  qualificationName?: Types.InputMaybe<Types.Scalars['String']>;
  issuer?: Types.InputMaybe<Types.Scalars['String']>;
  issueDate?: Types.InputMaybe<Types.Scalars['String']>;
  expirationDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddDocMutation = { __typename?: 'Mutation', addWorkerUpload?: { __typename?: 'AddWorkerUploadPayload', documentType?: { __typename?: 'DocumentType', id: number, title?: string | null, mid?: string | null, allowedFormats?: string | null, documentNumberVisibility?: number | null, documentTitleVisibility?: number | null, issuerVisibility?: number | null, issueDateVisibility?: number | null, expirationDateVisibility?: number | null, multipleUploadsAllowed?: boolean | null, workerUploads?: Array<{ __typename?: 'WorkerUpload', id: number, issuer?: string | null, documentNumber?: string | null, documentTitle?: string | null, qualificationName?: string | null, issueDate?: string | null, expirationDate?: string | null, imageUrl?: string | null, fileName?: string | null, trashedAt?: string | null }> | null, archivedWorkerUploads?: Array<{ __typename?: 'WorkerUpload', id: number, issuer?: string | null, documentNumber?: string | null, documentTitle?: string | null, qualificationName?: string | null, issueDate?: string | null, expirationDate?: string | null, imageUrl?: string | null, fileName?: string | null, trashedAt?: string | null }> | null } | null, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };

export type InternalAppMutationVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  answer?: Types.InputMaybe<Types.Scalars['String']>;
  signature: Types.Scalars['String'];
}>;


export type InternalAppMutation = { __typename?: 'Mutation', internalApplication?: { __typename?: 'InternalApplicationPayload', errors: Array<{ __typename?: 'ValidationError', message: string, attribute?: string | null }>, organisationRegistration?: { __typename?: 'OrganisationRegistration', id: number } | null } | null };

export type ExternalApplicationMutationMutationVariables = Types.Exact<{
  organisationId: Types.Scalars['Int'];
  signature: Types.Scalars['String'];
  answer?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ExternalApplicationMutationMutation = { __typename?: 'Mutation', externalApplication?: { __typename?: 'ExternalApplicationPayload', errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }>, organisationRegistration?: { __typename?: 'OrganisationRegistration', id: number } | null } | null };

export type JoinCollaborativeBankMutationMutationVariables = Types.Exact<{
  collaborativeBankId: Types.Scalars['Int'];
  primaryOrganisationId: Types.Scalars['Int'];
  signature: Types.Scalars['String'];
}>;


export type JoinCollaborativeBankMutationMutation = { __typename?: 'Mutation', joinCollaborativeBank?: { __typename?: 'JoinCollaborativeBankPayload', errors: Array<{ __typename?: 'ValidationError', message: string }>, workerCollaborativeBank?: { __typename?: 'WorkerCollaborativeBank', active: boolean, id: number } | null } | null };

export type VmsWorkerConfirmRegistrationMutationVariables = Types.Exact<{
  agencyRegistrationId: Types.Scalars['Int'];
}>;


export type VmsWorkerConfirmRegistrationMutation = { __typename?: 'Mutation', vmsWorkerConfirmRegistration?: { __typename?: 'ConfirmRegistrationPayload', success: boolean, errors: Array<{ __typename?: 'ValidationError', attribute?: string | null, message: string }> } | null };


export const UpdateProfileDocument = gql`
    mutation updateProfile($staffGroupId: Int, $postcode: String, $regBodyNumber: String, $firstName: String, $lastName: String, $dateOfBirth: String, $phoneNumber: String, $addressLine1: String, $addressLine2: String, $city: String, $preferredName: String, $dbsNumber: String, $niNumber: String, $filterGradeId: Int, $ediGenderId: Int, $ediConsent: Boolean, $ediCountryOfQualificationId: Int, $ediDisabilityId: Int, $ediEthnicityId: Int) {
  workerOnboardingUpdateProfile(
    staffGroupId: $staffGroupId
    postcode: $postcode
    regBodyNumber: $regBodyNumber
    firstName: $firstName
    lastName: $lastName
    dateOfBirth: $dateOfBirth
    phoneNumber: $phoneNumber
    addressLine1: $addressLine1
    addressLine2: $addressLine2
    city: $city
    preferredName: $preferredName
    filterGradeId: $filterGradeId
    dbsNumber: $dbsNumber
    niNumber: $niNumber
    ediGenderId: $ediGenderId
    ediConsent: $ediConsent
    ediCountryOfQualificationId: $ediCountryOfQualificationId
    ediDisabilityId: $ediDisabilityId
    ediEthnicityId: $ediEthnicityId
  ) {
    worker {
      firstName
      lastName
      phoneNumber
      gender
      dateOfBirth
      email
      ediGender {
        id
        value
      }
      staffGroup {
        id
        specialities {
          id
          title
        }
      }
      postcode
      regBodyNumber
    }
    errors {
      attribute
      message
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      staffGroupId: // value for 'staffGroupId'
 *      postcode: // value for 'postcode'
 *      regBodyNumber: // value for 'regBodyNumber'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      phoneNumber: // value for 'phoneNumber'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      preferredName: // value for 'preferredName'
 *      dbsNumber: // value for 'dbsNumber'
 *      niNumber: // value for 'niNumber'
 *      filterGradeId: // value for 'filterGradeId'
 *      ediGenderId: // value for 'ediGenderId'
 *      ediConsent: // value for 'ediConsent'
 *      ediCountryOfQualificationId: // value for 'ediCountryOfQualificationId'
 *      ediDisabilityId: // value for 'ediDisabilityId'
 *      ediEthnicityId: // value for 'ediEthnicityId'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const DeleteProfilePicDocument = gql`
    mutation deleteProfilePic {
  deleteProfilePicture {
    errors {
      message
    }
    worker {
      email
      profilePictureUrl
    }
  }
}
    `;
export type DeleteProfilePicMutationFn = Apollo.MutationFunction<DeleteProfilePicMutation, DeleteProfilePicMutationVariables>;

/**
 * __useDeleteProfilePicMutation__
 *
 * To run a mutation, you first call `useDeleteProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfilePicMutation, { data, loading, error }] = useDeleteProfilePicMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteProfilePicMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfilePicMutation, DeleteProfilePicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfilePicMutation, DeleteProfilePicMutationVariables>(DeleteProfilePicDocument, options);
      }
export type DeleteProfilePicMutationHookResult = ReturnType<typeof useDeleteProfilePicMutation>;
export type DeleteProfilePicMutationResult = Apollo.MutationResult<DeleteProfilePicMutation>;
export type DeleteProfilePicMutationOptions = Apollo.BaseMutationOptions<DeleteProfilePicMutation, DeleteProfilePicMutationVariables>;
export const UpdateEmploymentDocument = gql`
    mutation updateEmployment($dbsNumber: String, $niNumber: String, $filterGradeId: Int, $specialityIds: [Int!]) {
  updateEmploymentInfo(
    dbsNumber: $dbsNumber
    niNumber: $niNumber
    filterGradeId: $filterGradeId
    specialityIds: $specialityIds
  ) {
    worker {
      niNumber
      dbsNumber
      id
      filterGrade {
        id
      }
      specialities {
        id
      }
    }
    errors {
      attribute
      message
    }
  }
}
    `;
export type UpdateEmploymentMutationFn = Apollo.MutationFunction<UpdateEmploymentMutation, UpdateEmploymentMutationVariables>;

/**
 * __useUpdateEmploymentMutation__
 *
 * To run a mutation, you first call `useUpdateEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmploymentMutation, { data, loading, error }] = useUpdateEmploymentMutation({
 *   variables: {
 *      dbsNumber: // value for 'dbsNumber'
 *      niNumber: // value for 'niNumber'
 *      filterGradeId: // value for 'filterGradeId'
 *      specialityIds: // value for 'specialityIds'
 *   },
 * });
 */
export function useUpdateEmploymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmploymentMutation, UpdateEmploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmploymentMutation, UpdateEmploymentMutationVariables>(UpdateEmploymentDocument, options);
      }
export type UpdateEmploymentMutationHookResult = ReturnType<typeof useUpdateEmploymentMutation>;
export type UpdateEmploymentMutationResult = Apollo.MutationResult<UpdateEmploymentMutation>;
export type UpdateEmploymentMutationOptions = Apollo.BaseMutationOptions<UpdateEmploymentMutation, UpdateEmploymentMutationVariables>;
export const UpdateAvatarDocument = gql`
    mutation updateAvatar($file: String!, $fileName: String!) {
  uploadProfilePicture(file: $file, fileName: $fileName) {
    worker {
      firstName
    }
    errors {
      attribute
      message
    }
  }
}
    `;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<UpdateAvatarMutation, UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>;
export const SendResetPasswordInstructionsDocument = gql`
    mutation sendResetPasswordInstructions($email: String!) {
  sendResetPasswordInstructions(email: $email)
}
    `;
export type SendResetPasswordInstructionsMutationFn = Apollo.MutationFunction<SendResetPasswordInstructionsMutation, SendResetPasswordInstructionsMutationVariables>;

/**
 * __useSendResetPasswordInstructionsMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordInstructionsMutation, { data, loading, error }] = useSendResetPasswordInstructionsMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordInstructionsMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordInstructionsMutation, SendResetPasswordInstructionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordInstructionsMutation, SendResetPasswordInstructionsMutationVariables>(SendResetPasswordInstructionsDocument, options);
      }
export type SendResetPasswordInstructionsMutationHookResult = ReturnType<typeof useSendResetPasswordInstructionsMutation>;
export type SendResetPasswordInstructionsMutationResult = Apollo.MutationResult<SendResetPasswordInstructionsMutation>;
export type SendResetPasswordInstructionsMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordInstructionsMutation, SendResetPasswordInstructionsMutationVariables>;
export const RefreshTokenMutationDocument = gql`
    mutation RefreshTokenMutation($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    refreshToken
    token
  }
}
    `;
export type RefreshTokenMutationMutationFn = Apollo.MutationFunction<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>;

/**
 * __useRefreshTokenMutationMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutationMutation, { data, loading, error }] = useRefreshTokenMutationMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutationMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>(RefreshTokenMutationDocument, options);
      }
export type RefreshTokenMutationMutationHookResult = ReturnType<typeof useRefreshTokenMutationMutation>;
export type RefreshTokenMutationMutationResult = Apollo.MutationResult<RefreshTokenMutationMutation>;
export type RefreshTokenMutationMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>;
export const DeleteDocDocument = gql`
    mutation deleteDoc($id: Int!) {
  deleteWorkerUpload(id: $id) {
    documentType {
      id
      title
      mid
      allowedFormats
      documentNumberVisibility
      documentTitleVisibility
      issuerVisibility
      issueDateVisibility
      expirationDateVisibility
      qualificationNameVisibility
      multipleUploadsAllowed
      workerUploads {
        id
        issuer
        documentNumber
        documentTitle
        qualificationName
        issueDate
        expirationDate
        imageUrl
        fileName
        trashedAt
      }
      archivedWorkerUploads {
        id
        issuer
        documentNumber
        documentTitle
        qualificationName
        issueDate
        expirationDate
        imageUrl
        fileName
        trashedAt
      }
    }
    errors {
      attribute
      message
    }
  }
}
    `;
export type DeleteDocMutationFn = Apollo.MutationFunction<DeleteDocMutation, DeleteDocMutationVariables>;

/**
 * __useDeleteDocMutation__
 *
 * To run a mutation, you first call `useDeleteDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocMutation, { data, loading, error }] = useDeleteDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocMutation, DeleteDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocMutation, DeleteDocMutationVariables>(DeleteDocDocument, options);
      }
export type DeleteDocMutationHookResult = ReturnType<typeof useDeleteDocMutation>;
export type DeleteDocMutationResult = Apollo.MutationResult<DeleteDocMutation>;
export type DeleteDocMutationOptions = Apollo.BaseMutationOptions<DeleteDocMutation, DeleteDocMutationVariables>;
export const AddDocDocument = gql`
    mutation addDoc($file: String!, $fileName: String!, $documentTypeId: Int!, $documentNumber: String, $documentTitle: String, $qualificationName: String, $issuer: String, $issueDate: String, $expirationDate: String) {
  addWorkerUpload(
    file: $file
    fileName: $fileName
    documentTypeId: $documentTypeId
    documentNumber: $documentNumber
    documentTitle: $documentTitle
    issuer: $issuer
    qualificationName: $qualificationName
    issueDate: $issueDate
    expirationDate: $expirationDate
  ) {
    documentType {
      id
      title
      mid
      allowedFormats
      documentNumberVisibility
      documentTitleVisibility
      issuerVisibility
      issueDateVisibility
      expirationDateVisibility
      multipleUploadsAllowed
      workerUploads {
        id
        issuer
        documentNumber
        documentTitle
        qualificationName
        issueDate
        expirationDate
        imageUrl
        fileName
        trashedAt
      }
      archivedWorkerUploads {
        id
        issuer
        documentNumber
        documentTitle
        qualificationName
        issueDate
        expirationDate
        imageUrl
        fileName
        trashedAt
      }
    }
    errors {
      attribute
      message
    }
  }
}
    `;
export type AddDocMutationFn = Apollo.MutationFunction<AddDocMutation, AddDocMutationVariables>;

/**
 * __useAddDocMutation__
 *
 * To run a mutation, you first call `useAddDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocMutation, { data, loading, error }] = useAddDocMutation({
 *   variables: {
 *      file: // value for 'file'
 *      fileName: // value for 'fileName'
 *      documentTypeId: // value for 'documentTypeId'
 *      documentNumber: // value for 'documentNumber'
 *      documentTitle: // value for 'documentTitle'
 *      qualificationName: // value for 'qualificationName'
 *      issuer: // value for 'issuer'
 *      issueDate: // value for 'issueDate'
 *      expirationDate: // value for 'expirationDate'
 *   },
 * });
 */
export function useAddDocMutation(baseOptions?: Apollo.MutationHookOptions<AddDocMutation, AddDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDocMutation, AddDocMutationVariables>(AddDocDocument, options);
      }
export type AddDocMutationHookResult = ReturnType<typeof useAddDocMutation>;
export type AddDocMutationResult = Apollo.MutationResult<AddDocMutation>;
export type AddDocMutationOptions = Apollo.BaseMutationOptions<AddDocMutation, AddDocMutationVariables>;
export const InternalAppDocument = gql`
    mutation internalApp($organisationId: Int!, $answer: String, $signature: String!) {
  internalApplication(
    organisationId: $organisationId
    answer: $answer
    signature: $signature
  ) {
    errors {
      message
      attribute
    }
    organisationRegistration {
      id
    }
  }
}
    `;
export type InternalAppMutationFn = Apollo.MutationFunction<InternalAppMutation, InternalAppMutationVariables>;

/**
 * __useInternalAppMutation__
 *
 * To run a mutation, you first call `useInternalAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalAppMutation, { data, loading, error }] = useInternalAppMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      answer: // value for 'answer'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useInternalAppMutation(baseOptions?: Apollo.MutationHookOptions<InternalAppMutation, InternalAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InternalAppMutation, InternalAppMutationVariables>(InternalAppDocument, options);
      }
export type InternalAppMutationHookResult = ReturnType<typeof useInternalAppMutation>;
export type InternalAppMutationResult = Apollo.MutationResult<InternalAppMutation>;
export type InternalAppMutationOptions = Apollo.BaseMutationOptions<InternalAppMutation, InternalAppMutationVariables>;
export const ExternalApplicationMutationDocument = gql`
    mutation externalApplicationMutation($organisationId: Int!, $signature: String!, $answer: String) {
  externalApplication(
    organisationId: $organisationId
    signature: $signature
    answer: $answer
  ) {
    errors {
      attribute
      message
    }
    organisationRegistration {
      id
    }
  }
}
    `;
export type ExternalApplicationMutationMutationFn = Apollo.MutationFunction<ExternalApplicationMutationMutation, ExternalApplicationMutationMutationVariables>;

/**
 * __useExternalApplicationMutationMutation__
 *
 * To run a mutation, you first call `useExternalApplicationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalApplicationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalApplicationMutationMutation, { data, loading, error }] = useExternalApplicationMutationMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      signature: // value for 'signature'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useExternalApplicationMutationMutation(baseOptions?: Apollo.MutationHookOptions<ExternalApplicationMutationMutation, ExternalApplicationMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExternalApplicationMutationMutation, ExternalApplicationMutationMutationVariables>(ExternalApplicationMutationDocument, options);
      }
export type ExternalApplicationMutationMutationHookResult = ReturnType<typeof useExternalApplicationMutationMutation>;
export type ExternalApplicationMutationMutationResult = Apollo.MutationResult<ExternalApplicationMutationMutation>;
export type ExternalApplicationMutationMutationOptions = Apollo.BaseMutationOptions<ExternalApplicationMutationMutation, ExternalApplicationMutationMutationVariables>;
export const JoinCollaborativeBankMutationDocument = gql`
    mutation joinCollaborativeBankMutation($collaborativeBankId: Int!, $primaryOrganisationId: Int!, $signature: String!) {
  joinCollaborativeBank(
    collaborativeBankId: $collaborativeBankId
    primaryOrganisationId: $primaryOrganisationId
    signature: $signature
  ) {
    errors {
      message
    }
    workerCollaborativeBank {
      active
      id
    }
  }
}
    `;
export type JoinCollaborativeBankMutationMutationFn = Apollo.MutationFunction<JoinCollaborativeBankMutationMutation, JoinCollaborativeBankMutationMutationVariables>;

/**
 * __useJoinCollaborativeBankMutationMutation__
 *
 * To run a mutation, you first call `useJoinCollaborativeBankMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCollaborativeBankMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCollaborativeBankMutationMutation, { data, loading, error }] = useJoinCollaborativeBankMutationMutation({
 *   variables: {
 *      collaborativeBankId: // value for 'collaborativeBankId'
 *      primaryOrganisationId: // value for 'primaryOrganisationId'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useJoinCollaborativeBankMutationMutation(baseOptions?: Apollo.MutationHookOptions<JoinCollaborativeBankMutationMutation, JoinCollaborativeBankMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinCollaborativeBankMutationMutation, JoinCollaborativeBankMutationMutationVariables>(JoinCollaborativeBankMutationDocument, options);
      }
export type JoinCollaborativeBankMutationMutationHookResult = ReturnType<typeof useJoinCollaborativeBankMutationMutation>;
export type JoinCollaborativeBankMutationMutationResult = Apollo.MutationResult<JoinCollaborativeBankMutationMutation>;
export type JoinCollaborativeBankMutationMutationOptions = Apollo.BaseMutationOptions<JoinCollaborativeBankMutationMutation, JoinCollaborativeBankMutationMutationVariables>;
export const VmsWorkerConfirmRegistrationDocument = gql`
    mutation vmsWorkerConfirmRegistration($agencyRegistrationId: Int!) {
  vmsWorkerConfirmRegistration(agencyRegistrationId: $agencyRegistrationId) {
    errors {
      attribute
      message
    }
    success
  }
}
    `;
export type VmsWorkerConfirmRegistrationMutationFn = Apollo.MutationFunction<VmsWorkerConfirmRegistrationMutation, VmsWorkerConfirmRegistrationMutationVariables>;

/**
 * __useVmsWorkerConfirmRegistrationMutation__
 *
 * To run a mutation, you first call `useVmsWorkerConfirmRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVmsWorkerConfirmRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vmsWorkerConfirmRegistrationMutation, { data, loading, error }] = useVmsWorkerConfirmRegistrationMutation({
 *   variables: {
 *      agencyRegistrationId: // value for 'agencyRegistrationId'
 *   },
 * });
 */
export function useVmsWorkerConfirmRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<VmsWorkerConfirmRegistrationMutation, VmsWorkerConfirmRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VmsWorkerConfirmRegistrationMutation, VmsWorkerConfirmRegistrationMutationVariables>(VmsWorkerConfirmRegistrationDocument, options);
      }
export type VmsWorkerConfirmRegistrationMutationHookResult = ReturnType<typeof useVmsWorkerConfirmRegistrationMutation>;
export type VmsWorkerConfirmRegistrationMutationResult = Apollo.MutationResult<VmsWorkerConfirmRegistrationMutation>;
export type VmsWorkerConfirmRegistrationMutationOptions = Apollo.BaseMutationOptions<VmsWorkerConfirmRegistrationMutation, VmsWorkerConfirmRegistrationMutationVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateProfileMutation((req, res, ctx) => {
 *   const { staffGroupId, postcode, regBodyNumber, firstName, lastName, dateOfBirth, phoneNumber, addressLine1, addressLine2, city, preferredName, dbsNumber, niNumber, filterGradeId, ediGenderId, ediConsent, ediCountryOfQualificationId, ediDisabilityId, ediEthnicityId } = req.variables;
 *   return res(
 *     ctx.data({ workerOnboardingUpdateProfile })
 *   )
 * })
 */
export const mockUpdateProfileMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateProfileMutationVariables>, GraphQLContext<UpdateProfileMutation>, any>) =>
  graphql.mutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    'updateProfile',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteProfilePicMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ deleteProfilePicture })
 *   )
 * })
 */
export const mockDeleteProfilePicMutation = (resolver: ResponseResolver<GraphQLRequest<DeleteProfilePicMutationVariables>, GraphQLContext<DeleteProfilePicMutation>, any>) =>
  graphql.mutation<DeleteProfilePicMutation, DeleteProfilePicMutationVariables>(
    'deleteProfilePic',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateEmploymentMutation((req, res, ctx) => {
 *   const { dbsNumber, niNumber, filterGradeId, specialityIds } = req.variables;
 *   return res(
 *     ctx.data({ updateEmploymentInfo })
 *   )
 * })
 */
export const mockUpdateEmploymentMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateEmploymentMutationVariables>, GraphQLContext<UpdateEmploymentMutation>, any>) =>
  graphql.mutation<UpdateEmploymentMutation, UpdateEmploymentMutationVariables>(
    'updateEmployment',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateAvatarMutation((req, res, ctx) => {
 *   const { file, fileName } = req.variables;
 *   return res(
 *     ctx.data({ uploadProfilePicture })
 *   )
 * })
 */
export const mockUpdateAvatarMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateAvatarMutationVariables>, GraphQLContext<UpdateAvatarMutation>, any>) =>
  graphql.mutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(
    'updateAvatar',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendResetPasswordInstructionsMutation((req, res, ctx) => {
 *   const { email } = req.variables;
 *   return res(
 *     ctx.data({ sendResetPasswordInstructions })
 *   )
 * })
 */
export const mockSendResetPasswordInstructionsMutation = (resolver: ResponseResolver<GraphQLRequest<SendResetPasswordInstructionsMutationVariables>, GraphQLContext<SendResetPasswordInstructionsMutation>, any>) =>
  graphql.mutation<SendResetPasswordInstructionsMutation, SendResetPasswordInstructionsMutationVariables>(
    'sendResetPasswordInstructions',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockRefreshTokenMutationMutation((req, res, ctx) => {
 *   const { refreshToken } = req.variables;
 *   return res(
 *     ctx.data({ refreshToken })
 *   )
 * })
 */
export const mockRefreshTokenMutationMutation = (resolver: ResponseResolver<GraphQLRequest<RefreshTokenMutationMutationVariables>, GraphQLContext<RefreshTokenMutationMutation>, any>) =>
  graphql.mutation<RefreshTokenMutationMutation, RefreshTokenMutationMutationVariables>(
    'RefreshTokenMutation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteDocMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteWorkerUpload })
 *   )
 * })
 */
export const mockDeleteDocMutation = (resolver: ResponseResolver<GraphQLRequest<DeleteDocMutationVariables>, GraphQLContext<DeleteDocMutation>, any>) =>
  graphql.mutation<DeleteDocMutation, DeleteDocMutationVariables>(
    'deleteDoc',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddDocMutation((req, res, ctx) => {
 *   const { file, fileName, documentTypeId, documentNumber, documentTitle, qualificationName, issuer, issueDate, expirationDate } = req.variables;
 *   return res(
 *     ctx.data({ addWorkerUpload })
 *   )
 * })
 */
export const mockAddDocMutation = (resolver: ResponseResolver<GraphQLRequest<AddDocMutationVariables>, GraphQLContext<AddDocMutation>, any>) =>
  graphql.mutation<AddDocMutation, AddDocMutationVariables>(
    'addDoc',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInternalAppMutation((req, res, ctx) => {
 *   const { organisationId, answer, signature } = req.variables;
 *   return res(
 *     ctx.data({ internalApplication })
 *   )
 * })
 */
export const mockInternalAppMutation = (resolver: ResponseResolver<GraphQLRequest<InternalAppMutationVariables>, GraphQLContext<InternalAppMutation>, any>) =>
  graphql.mutation<InternalAppMutation, InternalAppMutationVariables>(
    'internalApp',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockExternalApplicationMutationMutation((req, res, ctx) => {
 *   const { organisationId, signature, answer } = req.variables;
 *   return res(
 *     ctx.data({ externalApplication })
 *   )
 * })
 */
export const mockExternalApplicationMutationMutation = (resolver: ResponseResolver<GraphQLRequest<ExternalApplicationMutationMutationVariables>, GraphQLContext<ExternalApplicationMutationMutation>, any>) =>
  graphql.mutation<ExternalApplicationMutationMutation, ExternalApplicationMutationMutationVariables>(
    'externalApplicationMutation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockJoinCollaborativeBankMutationMutation((req, res, ctx) => {
 *   const { collaborativeBankId, primaryOrganisationId, signature } = req.variables;
 *   return res(
 *     ctx.data({ joinCollaborativeBank })
 *   )
 * })
 */
export const mockJoinCollaborativeBankMutationMutation = (resolver: ResponseResolver<GraphQLRequest<JoinCollaborativeBankMutationMutationVariables>, GraphQLContext<JoinCollaborativeBankMutationMutation>, any>) =>
  graphql.mutation<JoinCollaborativeBankMutationMutation, JoinCollaborativeBankMutationMutationVariables>(
    'joinCollaborativeBankMutation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVmsWorkerConfirmRegistrationMutation((req, res, ctx) => {
 *   const { agencyRegistrationId } = req.variables;
 *   return res(
 *     ctx.data({ vmsWorkerConfirmRegistration })
 *   )
 * })
 */
export const mockVmsWorkerConfirmRegistrationMutation = (resolver: ResponseResolver<GraphQLRequest<VmsWorkerConfirmRegistrationMutationVariables>, GraphQLContext<VmsWorkerConfirmRegistrationMutation>, any>) =>
  graphql.mutation<VmsWorkerConfirmRegistrationMutation, VmsWorkerConfirmRegistrationMutationVariables>(
    'vmsWorkerConfirmRegistration',
    resolver
  )
