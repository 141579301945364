import { createComponentFromSvg } from "@patchworkhealth/web-components";

export const PadlockIcon = createComponentFromSvg(
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="padlock"
  >
    <path
      d="M10.6667 6.50002V7.00002H11.1667C11.6971 7.00002 12.2058 7.21073 12.5809 7.58581C12.956 7.96088 13.1667 8.46959 13.1667 9.00002V14.8334C13.1667 15.3638 12.956 15.8725 12.5809 16.2476C12.2058 16.6226 11.6971 16.8334 11.1667 16.8334H2.83334C2.30291 16.8334 1.7942 16.6226 1.41913 16.2476C1.04406 15.8725 0.833344 15.3638 0.833344 14.8334V9.00002C0.833344 8.46959 1.04406 7.96088 1.41913 7.58581C1.7942 7.21073 2.30291 7.00002 2.83334 7.00002H3.33334V6.50002V4.83335C3.33334 3.86089 3.71965 2.92826 4.40729 2.24063C5.09492 1.553 6.02755 1.16669 7.00001 1.16669C7.97247 1.16669 8.9051 1.553 9.59273 2.24063C10.2804 2.92826 10.6667 3.86089 10.6667 4.83335V6.50002ZM9.50001 7.00002H10V6.50002V4.83335C10 4.0377 9.68394 3.27464 9.12133 2.71203C8.55872 2.14942 7.79566 1.83335 7.00001 1.83335C6.20436 1.83335 5.4413 2.14942 4.87869 2.71203C4.31608 3.27464 4.00001 4.0377 4.00001 4.83335V6.50002V7.00002H4.50001H9.50001ZM6.76431 10.431C6.82682 10.3685 6.91161 10.3334 7.00001 10.3334C7.08841 10.3334 7.1732 10.3685 7.23571 10.431C7.29822 10.4935 7.33334 10.5783 7.33334 10.6667V13.1667C7.33334 13.2551 7.29822 13.3399 7.23571 13.4024C7.1732 13.4649 7.08842 13.5 7.00001 13.5C6.9116 13.5 6.82682 13.4649 6.76431 13.4024C6.7018 13.3399 6.66668 13.2551 6.66668 13.1667V10.6667C6.66668 10.5783 6.7018 10.4935 6.76431 10.431ZM12.1095 15.7762C12.3595 15.5261 12.5 15.187 12.5 14.8334V9.00002C12.5 8.6464 12.3595 8.30726 12.1095 8.05721C11.8594 7.80716 11.5203 7.66669 11.1667 7.66669H2.83334C2.47972 7.66669 2.14058 7.80716 1.89053 8.05721C1.64049 8.30726 1.50001 8.6464 1.50001 9.00002V14.8334C1.50001 15.187 1.64049 15.5261 1.89053 15.7762C2.14058 16.0262 2.47972 16.1667 2.83334 16.1667H11.1667C11.5203 16.1667 11.8594 16.0262 12.1095 15.7762Z"
      fill="#7C7D80"
      stroke="#333333"
    />
  </svg>
);
