import moment from "moment";

import { ExtendedOrganisation } from "containers/organisations";
import { OrgCollabIcon } from "icons/organisationIcons";

import {
  OrganisationEmailIcon,
  OrganisationPhoneIcon,
} from "./OrganisationIcons";
import { defaultImgOnError, truncate } from "./OrganisationsHelpers";

interface OrganisationsProps {
  org: ExtendedOrganisation;
  tsoEmail: string;
  tsoPhone: string;
  handleOrgClick: (org: ExtendedOrganisation) => void;
}

const OrganisationsCard = ({
  org,
  handleOrgClick,
  tsoEmail,
  tsoPhone,
}: OrganisationsProps) => {
  return (
    <div
      onClick={() => handleOrgClick(org)}
      className="bg-white border rounded-lg shadow-md cursor-pointer h-[195px] px-4 py-5 relative w-[344px] m-3"
    >
      <img
        alt="Organisation"
        className="h-[40px] max-w-[150px] border mb-4"
        onError={defaultImgOnError}
        src={`${process.env.REACT_APP_API}/${org.logoUrl}`}
      />

      <p className="mb-4 text-lg font-semibold">{truncate(org.name || "")}</p>

      <div className="flex items-center mb-1 text-xs">
        <OrganisationPhoneIcon />
        <p>{tsoPhone}</p>
      </div>

      <div className="flex items-center mb-1 text-xs">
        <OrganisationEmailIcon />

        <p>{tsoEmail}</p>
      </div>

      {org.partOfCollab && (
        <>
          {org?.approved && !org.joinedCollabBank && (
            <div className="absolute flex items-center h-[21px] p-1 rounded top-4 right-2 text-[10px] bg-blue-100 font-medium ">
              <OrgCollabIcon />
              Collab Bank Available
            </div>
          )}

          {org?.approved && org.joinedCollabBank && (
            <div className="absolute flex items-center h-[21px] p-1 rounded top-4 right-2 text-[10px] bg-green-1 font-medium ">
              <OrgCollabIcon />
              Active on Collab Bank
            </div>
          )}

          {!org?.approved && (
            <div className="absolute flex items-center h-[21px] p-1 rounded top-4 right-2 text-[10px] bg-blue-100 font-medium ">
              <OrgCollabIcon />
              Part of Collab Bank
            </div>
          )}
        </>
      )}

      {/* Footer ----------------- */}
      {org.approved !== null && (
        <div
          style={{
            backgroundColor: org.approved ? "#F2FCF6" : "#FFE4D9",
          }}
          className="absolute bottom-0 left-0 right-0 flex items-center justify-center h-8 border-t rounded-b-md"
        >
          {org.approved === true && (
            <p className="text-xs">
              Activated on:{" "}
              <span className="font-semibold">
                {moment(org.approvedAt).format("DD/MM/YYYY")}
              </span>
            </p>
          )}

          {org.approved === false && (
            <p className="text-xs" style={{ color: "#FF763F" }}>
              Waiting for{" "}
              <span className="font-semibold">Organisation to approve</span>
            </p>
          )}
        </div>
      )}

      {/* Footer End ------------ */}
    </div>
  );
};

export default OrganisationsCard;
