import { useContext, useState } from "react";

import { Loading } from "@patchworkhealth/web-components";

import Layout from "components/Layout";
import {
  OrganisationCardFragment,
  useOrganisationCardsQuery,
} from "components/Organisations/__generated__/Organisations.generated";
import OrganisationsCard from "components/Organisations/OrganisationCard";
import OrganisationsHeader from "components/Organisations/OrganisationsHeader";
import OrganisationsJoinModal from "components/Organisations/OrganisationsJoinModal";
import OrganisationsViewModal from "components/Organisations/OrganisationsViewModal";
import { AppContext } from "context/AppContext";
import {
  hasJoinedCollabBank,
  hideLeadEmployerDetails,
} from "helpers/functions";

export interface ExtendedOrganisation extends OrganisationCardFragment {
  approved: boolean | null;
  requiresActivation: boolean | null;
  approvedAt: string | null;
  joinedCollabBank: boolean;
  partOfCollab: boolean;
  grade: string | null;
  esrNumber: string | null;
}

export interface OrganisationProps {
  tab: "my-orgs" | "all-orgs";
  search: string;
  organisations: ExtendedOrganisation[];
  myOrganisations: number[];
  joinModal: boolean;
  viewModal: boolean;
  selectedOrg: ExtendedOrganisation | null;
}

const initialInputs: OrganisationProps = {
  tab: "my-orgs",
  search: "",
  organisations: [],
  myOrganisations: [],
  joinModal: false,
  viewModal: false,
  selectedOrg: null,
};

const OrganisationsPageNew = () => {
  /* State --------------------------------------------- */

  const store = useContext(AppContext);
  const [inputs, setInputs] = useState(initialInputs);

  /* GraphQL ------------------------------------------- */

  const { loading, refetch, data } = useOrganisationCardsQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: ({ currentWorker, organisations }) => {
      const myOrgIds =
        currentWorker?.organisationRegistrations?.map(
          (org) => org.organisation.id
        ) || [];

      const extendedeOrg: ExtendedOrganisation[] = organisations.map((org) => {
        let matchedOrg = null;

        for (const reg of currentWorker?.organisationRegistrations || []) {
          if (reg.organisation.id === org.id) matchedOrg = reg;
        }
        return {
          ...org,
          approved: matchedOrg ? matchedOrg.approved : null,
          requiresActivation: matchedOrg ? matchedOrg.approved : null,
          approvedAt: matchedOrg ? matchedOrg.approvedAt : null,
          joinedCollabBank: false,
          partOfCollab: false,
          grade: matchedOrg ? matchedOrg.grade?.title ?? "" : null,
          esrNumber: matchedOrg ? matchedOrg.esrNumber ?? "" : null,
        };
      });

      const sortOrganisations: ExtendedOrganisation[] = extendedeOrg?.reduce(
        (prev: ExtendedOrganisation[], current: ExtendedOrganisation) => {
          let joinedCollabBank = false;
          let partOfCollab = false;

          if (current.collaborativeBank) {
            partOfCollab = !hideLeadEmployerDetails(
              current?.leadEmployer,
              current?.collaborativeBank?.usesLeadEmployerProcess
            );
            joinedCollabBank = hasJoinedCollabBank(
              current.collaborativeBank.id,
              store?.user?.workerCollaborativeBanks || []
            );
          }

          const organisationRegistrationsData = {
            ...current,
            joinedCollabBank,
            partOfCollab,
          };

          prev.push(organisationRegistrationsData);
          return prev;
        },
        []
      );

      setInputs({
        ...inputs,
        organisations: sortOrganisations,
        myOrganisations: myOrgIds,
      });
    },
  });

  /* Functions ----------------------------------------- */

  const handleOrgClick = (org: ExtendedOrganisation) => {
    setInputs({
      ...inputs,
      selectedOrg: org,
      viewModal: true,
    });
  };

  const handleJoinOrg = (org: ExtendedOrganisation) => {
    setInputs({
      ...inputs,
      selectedOrg: org,
      viewModal: false,
      joinModal: true,
    });
  };

  const handleCloseModal = () => {
    setInputs({
      ...inputs,
      selectedOrg: null,
      viewModal: false,
      joinModal: false,
    });
  };

  const filteredOrgs = inputs.organisations?.filter(({ name }) =>
    name?.toLowerCase().includes(inputs.search.toLowerCase())
  );

  const myOrgs = inputs.organisations?.filter((org) =>
    inputs.myOrganisations.includes(org.id)
  );

  const findEmail = (org: ExtendedOrganisation) =>
    org.organisationStaffGroups.find(
      (item) =>
        item.staffGroup?.idValue === data?.currentWorker?.staffGroup?.idValue
    )?.tsoEmail ?? "";
  const findPhone = (org: ExtendedOrganisation) =>
    org.organisationStaffGroups.find(
      (item) =>
        item.staffGroup?.idValue === data?.currentWorker?.staffGroup?.idValue
    )?.tsoPhone ?? "";

  const handleRefetch = () => refetch();

  return (
    <Layout headerValueText="Organisations">
      <OrganisationsHeader
        inputs={inputs}
        setInputs={setInputs}
        myOrgs={myOrgs}
        refetch={handleRefetch}
      />

      {inputs.tab === "my-orgs" && (
        <>
          {loading && <Loading />}
          {!loading && inputs.organisations.length === 0 && (
            <div className="mt-5 text-center">
              <p className="text-lg font-semibold">No organisations found.</p>
            </div>
          )}

          {!loading && myOrgs.length > 0 && (
            <div className="flex flex-wrap">
              {myOrgs.map((org) => (
                <OrganisationsCard
                  key={org.id}
                  org={org}
                  handleOrgClick={handleOrgClick}
                  tsoEmail={findEmail(org)}
                  tsoPhone={findPhone(org)}
                />
              ))}
            </div>
          )}
        </>
      )}

      {inputs.tab === "all-orgs" && (
        <>
          {loading && <Loading />}
          {!loading && inputs.organisations.length === 0 && (
            <div className="mt-5 text-center">
              <p className="text-lg font-semibold">No organisations found.</p>
            </div>
          )}

          {!loading && filteredOrgs.length > 0 && (
            <div className="flex flex-wrap">
              {filteredOrgs.map((org) => (
                <OrganisationsCard
                  key={org.id}
                  org={org}
                  handleOrgClick={handleOrgClick}
                  tsoEmail={findEmail(org)}
                  tsoPhone={findPhone(org)}
                />
              ))}
            </div>
          )}
        </>
      )}

      {/* Modals -------------------------------------------- */}

      {inputs.viewModal && (
        <OrganisationsViewModal
          org={inputs.selectedOrg}
          onHide={handleCloseModal}
          handleJoinOrg={handleJoinOrg}
          refetch={handleRefetch}
        />
      )}

      {inputs.joinModal && (
        <OrganisationsJoinModal
          org={inputs.selectedOrg}
          onHide={handleCloseModal}
        />
      )}
    </Layout>
  );
};

export default OrganisationsPageNew;
