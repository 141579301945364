import styled from "styled-components";

import { RBCYAN } from "@patchworkhealth/web-components";

import { PadlockIcon } from "icons/PadlockIcon";

export const ModalUnlockBox = () => {
  return (
    <Container>
      <InnerWrapper>
        {" "}
        <PadlockIcon size={20} style={{ margin: "5px" }} />
        <Text>
          <b>Leave locked: </b>Editing period has passed - unlock leave to make
          changes
        </Text>
      </InnerWrapper>
    </Container>
  );
};

const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  align-items: center;
  background: ${RBCYAN.three};
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 32px;
`;

const Text = styled.p`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
  margin: 10px;
  width: 500px;
`;
