import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ApiPreferenceType } from "@patchworkhealth/selfrostering-api";
import { BLUE, Loading } from "@patchworkhealth/web-components";

import { ShiftIcon } from "components/Shared/ShiftIcon";
import { AppContext } from "context/AppContext";
import {
  rosteringPeriodApi,
  selfRosteringPreferencesApi,
} from "helpers/kmonoApiClient";
import { classNames } from "helpers/newHelpers";

import { workerCanSubmit } from "./rosterPeriodHelper";

interface Props {
  rosteringPeriodId: string;
  shiftName: string;
  preferenceType?: ApiPreferenceType;
  index: number;
  date: Date;
}

interface MutationKey {
  date: Date;
  shiftName: string;
  preferenceType: ApiPreferenceType;
}

interface UnsetMutationKey {
  date: Date;
  shiftName: string;
}

const SelfRosteringPreferenceButton = ({
  rosteringPeriodId,
  shiftName,
  preferenceType,
  index,
  date,
}: Props) => {
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();

  const negativeColour = "rgb(230 82 93)";

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setClicked(false);
  }, [preferenceType]);

  const { data: rosteringPeriod } = useQuery(
    ["selfRosteringSubmission", rosteringPeriodId],
    () => {
      return rosteringPeriodApi.getApiRosteringPeriodsRosteringPeriodId({
        rosteringPeriodId,
      });
    }
  );

  const { mutate: setPreference } = useMutation(
    ({ date, shiftName, preferenceType }: MutationKey): Promise<void> => {
      return selfRosteringPreferencesApi
        .postApiSelfRosteringPeriodPreferencesRosteringPeriodId({
          rosteringPeriodId,
          apiPreferenceRequest: { date, shiftName, preferenceType },
        })
        .then(() => queryClient.invalidateQueries("selfRosteringPreferences"))
        .catch(console.error);
    }
  );

  const { mutate: unsetPreference } = useMutation(
    ({ date, shiftName }: UnsetMutationKey): Promise<void> => {
      return selfRosteringPreferencesApi
        .deleteApiSelfRosteringPeriodPreferencesRosteringPeriodId({
          rosteringPeriodId,
          apiPreferenceRequest: { date, shiftName },
        })
        .then(() => queryClient.invalidateQueries("selfRosteringPreferences"))
        .catch(console.error);
    }
  );

  const selectedBorderColour = (): string => {
    if (preferenceType === "POSITIVE") {
      return borderColour(index);
    }
    if (preferenceType === "NEGATIVE") {
      return negativeColour;
    }
    return "rgb(166,172,179)";
  };

  const selectedMainColour = (): string => {
    if (preferenceType === "POSITIVE") {
      return mainColour(index);
    }
    if (preferenceType === "NEGATIVE") {
      return "rgb(255,255,255)";
    }
    return "rgb(237,239,241)";
  };

  const toggle = () => {
    setClicked(true);
    if (preferenceType === undefined) {
      setPreference({ date: date, shiftName, preferenceType: "POSITIVE" });
    } else if (preferenceType === "POSITIVE") {
      setPreference({ date: date, shiftName, preferenceType: "NEGATIVE" });
    } else {
      unsetPreference({ date: date, shiftName });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: 6,
        cursor: "pointer",
        opacity: clicked ? 0.7 : 1,
        transition: "transform 0.1s ease",
        transform: clicked ? "scale(0.95) translateY(1px)" : undefined,
        pointerEvents: workerCanSubmit(rosteringPeriod, user?.email)
          ? "auto"
          : "none",
      }}
      className={preferenceType ? undefined : "grayscale"}
      onClick={toggle}
      key={`${date}-${shiftName}-${index}`}
    >
      <div
        className={classNames("text-blue-6 px-[4px] py-[4px] rounded-l-md")}
        style={{ backgroundColor: selectedBorderColour() }}
      />
      <div
        className={classNames(
          "flex justify-between items-center px-[4px] py-[4px] rounded-r-md flex-1 text-xs"
        )}
        style={{
          backgroundColor: selectedMainColour(),
          transitionDuration: "200ms",
          border:
            preferenceType === "NEGATIVE"
              ? `1px solid ${negativeColour}`
              : `1px solid ${selectedMainColour()}`,
          textDecoration:
            preferenceType === "NEGATIVE" ? "line-through" : undefined,
          color:
            preferenceType === "NEGATIVE" ? negativeColour : "rgb(8,41,57)",
        }}
      >
        {shiftName}

        <div style={{ width: 20, height: 20 }}>
          {clicked ? (
            <Loading style={{ width: 16, height: 16 }} />
          ) : preferenceType === "NEGATIVE" ? (
            <div
              style={{
                backgroundColor: negativeColour,
                width: 21,
                height: 21,
                borderRadius: 12,
              }}
            />
          ) : (
            <ShiftIcon type={shiftName} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelfRosteringPreferenceButton;

const shiftBorderColours = [
  BLUE.seven,
  "rgb(230,169,94)",
  "rgb(6,11,147)",
  "rgb(23,62,115)",
];

const borderColour = (index: number) =>
  shiftBorderColours[index % shiftBorderColours.length];

const shiftMainColours = [
  "rgb(209, 242, 247)",
  "rgb(247,237,225)",
  "rgb(231,232,253)",
  "rgb(205,223,238)",
];

const mainColour = (index: number) =>
  shiftMainColours[index % shiftMainColours.length];
